export class Labels {
  public authLabels = {
    paramError: 'Você não tem permissão para acessar essa aplicação.',
    paramTitle: 'Desculpe',
  };
  public dashboardLabels = {
    modalErrorTitle: 'Ooops!',
    modalErrorDesc: 'Algo deu errado, tente novamente mais tarde.',
  };
}
