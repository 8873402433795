import { Injectable } from '@angular/core';
import * as FeatureFlags from '../../assets/feature-flags.json';
import { environment } from 'src/environments/environment';

@Injectable({providedIn: 'root'})
export class FeatureFlagsService {

  public loadFeatureFlags(): void {
    const env = environment.env;
    const chatbotFlag = FeatureFlags[env]['chatbot'];
    
    sessionStorage.setItem('chatbotIsActivated', JSON.stringify(chatbotFlag));
  }
  
}
