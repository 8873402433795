<div class="dashboard">
    <ca-header
      loadProducts
      applicationTitle="Menu de produtos"
      [accessToken]="accessToken"
      (logoutEvent)="logout($event)"
      (headerEvent)="response($event)"
      (modalContractSuccessClosedEvent)="modalContractSuccessClosedEvent($event)"
      [ApplicationLinks]="ApplicationLinks"
    ></ca-header>

    <ca-search
      *ngIf="!isTouring"
      (searchResponse)="renderResponse($event)"
    ></ca-search>

    <ca-notification *ngIf="!isTouring" [ApplicationLinks]="ApplicationLinks"></ca-notification>
    
    <app-button-serasa-alerta *ngIf="myCards && !isTouring" [myProduct]="myCards"></app-button-serasa-alerta>

    <ca-card-list-view
      *ngIf="!isTouring"
      class="main"
      (cardListViewEvent)="response($event)"
      [(cards)]="myCards"
    ></ca-card-list-view>

    <app-tour [isTouring]="isTouring"></app-tour>

    <ca-footer>
      <p>
        ©currentYear Experian Information Solutions, Inc. Experian Marketing
        Services, all rights reserved
      </p>
      <p>
        Experian and the Experian marks used herein are service marks or
        registered trademarks of Experian Informations Solutions, Inc
      </p>
      <p>
        Other product and company names mentioned herein are the property of
        their respective owners.
      </p>
    </ca-footer>

    <ca-modal [open]="openModalError" cssClass="modal-error">
      <div ca-modal-title>
        <em class="material-icons modal-error__icon">clear</em>
        <br />
        {{ modalErrorTitle }}
      </div>
      <div ca-modal-content>{{ modalErrorDesc }}</div>
    </ca-modal>

    <ca-modal [open]="isTourVirtualModalOpened" cssClass="modal-tour-virtual">
      <div ca-modal-content class="tour-virtual-content">
        <div class="tour-virtual-content__left">
          <img 
            class="tour-virtual-content__left__img"
            src="./assets/images/tour_virtual.webp" 
            alt="Imagem de tour virtual - Temos novidades">
        </div>
        <div class="tour-virtual-content__right">
          <h2 class="tour-virtual-content__right__title">Temos novidades!</h2>
          <p class="tour-virtual-content__right__text">
            Reorganizamos o <strong>Novo Menu de Produtos</strong> e agora ele possui todas as funcionalidades que você precisa em seu dia a dia.
            <br /> <br />
            Quer saber como ficou?
          </p>
          <div class="tour-virtual-content__right__buttons">
            <a href="javascript:;" (click)="skippedTour()">Pular tour</a>
            <button 
              eds-button motif="primary"
              data-testid="btn-iniciar-tour"
              (click)="clickModalInitTour()"
              class="tour-virtual-content__right__buttons__button"
              [routerLink]="['/']" 
              [queryParams]="{starttour: 'force'}">Iniciar tour
            </button>
          </div>
        </div>
      </div>
    </ca-modal>
</div>

<ngx-guided-tour 
  skipText="Pular tour" 
  nextText ="Próximo" 
  backText="Anterior" 
  doneText="Ok, entendi!" 
  closeText="Fechar"
  minimalTourStepWidth ="457"
  tourStepWidth="457"
  progressIndicatorLocation="top-of-tour-block"
  [progressIndicator]="progressIndicator">
</ngx-guided-tour>

<ng-template #progressIndicator>
  {{ tourService?.currentTourStepDisplay }} de {{ tourService?.currentTourStepCount }}
</ng-template>

