import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { EnvironmentService } from '@experian-shared/services'; // NOSONAR
import { SessionManager } from '@experian/authentication'; // NOSONAR
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { endpoints } from '../shared/endpoints';

@Injectable()
export class AuthService {

  constructor(public jwtHelper: JwtHelperService,
              private readonly http: HttpClient,
              public environmentService: EnvironmentService,
              private readonly sesisonManager: SessionManager) {}

  public isAuthenticated(token): boolean {
    return !this.jwtHelper.isTokenExpired(token);
  }

  public isAuthorizedEmail(userId: any, contactId: any, tokenId: any): Observable<any> {
    const API_URL = `${this.environmentService.env.source}${endpoints.sendAuthorizedEmail}/${userId}/alternative-emails/${contactId}/authorize`;
    let accessToken = this.sesisonManager.accessToken;
    accessToken =  (accessToken !== null && accessToken !== undefined && accessToken !== '') ? accessToken :  localStorage.getItem('app-identity-token');
    const headers = this.simpleHeaders.append(
      // eslint-disable-next-line
      'Authorization', //NOSONAR
      `Bearer ${accessToken}`
    );
    return this.http.post(
      API_URL,
      {
        token: tokenId
      },
      { headers }
    ).pipe(
      map((ret: any) => { //NOSONAR
        // eslint-disable-next-line
        ret = { validated: true }; //NOSONAR
        return ret;
      }),
      catchError((error: HttpErrorResponse) => {
        // eslint-disable-next-line
        return of({ validated: false, error: error }); //NOSONAR
      }),
    );
  }

  private get simpleHeaders(): HttpHeaders {
    return new HttpHeaders({ 'Content-Type': 'application/json' });
  }
}
