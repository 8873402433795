import { NgModule, inject } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CheckSessionPMEGuard } from './guards/check-session-pme.guard';

export function authModule() {
  return import('./components/auth/auth.module').then((m) => m.AuthModule);
}

export const routes: Routes = [
  {
    path: 'login',
    loadChildren: authModule,
  },
  {
    path: 'my-profile',
    loadChildren: () =>
      import('./shared/myProfileWrapper.module').then(
        (m) => m.MyProfileWrapperModule
      ),
    outlet: 'my-profile',
  },
  {
    path: 'voltar',
    canActivate: [
      () => {
        inject(CheckSessionPMEGuard).canActivate();
      },
    ],
    children: [],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
