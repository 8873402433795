import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, inject } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ExperianHeaderModule, CardListViewModule, HomeNotificationsModule } from '@experian/header'; // NOSONAR
import { FooterModule, ModalModule } from '@experian-shared'; // NOSONAR
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';

import { environment } from '../../../environments/environment';
import { DashboardComponent } from './dashboard.component';
import { TourMockupComponent } from '../tour/tour.component';
import { ButtonSerasaAlertaComponent } from '../button-serasa-alerta/button-serasa-alerta.component';
import { AnalyticsButtonDirective } from '../../shared/directives/analytics-button.directive';
import { CanActivateDashboard } from '../../services/dashboard-activate-guard.service';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [() => {
      inject(CanActivateDashboard).canActivate()
    }]
  },
];

@NgModule({
  declarations: [
    DashboardComponent,
    TourMockupComponent,
    AnalyticsButtonDirective,
    ButtonSerasaAlertaComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes,   {
      preloadingStrategy: PreloadAllModules
    }),
    ExperianHeaderModule.forRoot(environment),
    GuidedTourModule,
    HomeNotificationsModule,
    CardListViewModule,
    ModalModule,
    FooterModule
  ],
  exports: [
    RouterModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    GuidedTourService,
  ]
})
export class DashboardModule {}
