import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SessionManager } from '@experian/authentication'; // NOSONAR

@Injectable({
  providedIn: 'root'
})
export class CanActivateDashboard implements CanActivate {
  constructor(
    private readonly sessionManager: SessionManager,
    private readonly router: Router
  ) { }

  public canActivate(): boolean {
    const accessToken = this.sessionManager.accessToken?.trim() || '';
    const forbiddenTokenValues = ['', 'null', 'undefined'];

    if (forbiddenTokenValues.includes(accessToken)) {
      this.router.navigate(['/login']);
      return false;
    }

    return true;
  }
}


