import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

type activateReturn =
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree;
@Injectable({
  providedIn: 'root',
})
export class CheckSessionPMEGuard {
  constructor(private router: Router, private window: Window) { }

  canActivate(): activateReturn {
    const token = localStorage.getItem('accessToken');
    
    if (token && token !== 'undefined') {
      this.router.navigate([''])
    } else {
      this.window.location.href = environment.PMEProductMenuUrl;
    }
    
    return true;
  }
}
