import { Directive, ElementRef, Input, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: '[appAnalyticsButton]'
})
export class AnalyticsButtonDirective implements OnInit {
  private _launchLabel: string;
  @Input() set launchLabel(value: string) {
    this._launchLabel = value;
    this.renderer.setAttribute(this.el.nativeElement, 'data-launch-label', this.launchLabel || this.getElementText(this.el));
  }

  get launchLabel(): string {
    return this._launchLabel;
  }

  @Input() launchSiteSection = 'Dash';
  @Input() launchSection: string;

  constructor(
    readonly el: ElementRef,
    readonly renderer: Renderer2,
  ) {  }

  public get element() {return this.el;}

  ngOnInit() {
    this.addLaunchDataAttributes();
    this.renderer.addClass(this.el.nativeElement, 'launch-event');
  }

  getElementText($el: ElementRef): string {
    return $el.nativeElement.innerText.trim().toLowerCase().substring(0, 25);
  }

  addLaunchDataAttributes() {
    this.renderer.setAttribute(this.el.nativeElement, 'data-launch-site-section', this.launchSiteSection);
    this.renderer.setAttribute(this.el.nativeElement, 'data-launch-label', this.launchLabel || this.getElementText(this.el));
    this.renderer.setAttribute(this.el.nativeElement, 'data-launch-section', this.launchSection);
  }
}

