import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { FooterModule, ModalModule } from '@experian-shared'; // NOSONAR
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import {
  IRetryConfig,
  RetryPolicyInterceptor,
} from '@experian-shared/services'; // NOSONAR
import { CardListViewModule } from '@experian/header'; // NOSONAR
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './services/auth.service';
import { ModalFirstAccessSerasaAlertaComponent } from './components/modal-first-access-serasa-alerta/modal-first-access-serasa-alerta.component';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { FeatureFlagsService } from './services/feature-flags.service';
import * as FeatureFlags from '../assets/feature-flags.json';

const envRetryConfig: IRetryConfig =
  environment.retryPolicyConfig as IRetryConfig;
@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent, 
    ModalFirstAccessSerasaAlertaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DashboardModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CardListViewModule,
    FooterModule,
    ModalModule,
    MatDialogModule,
    MatIconModule,
  ],
  providers: [
    AuthService,
    JwtHelperService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    { provide: 'FEATURE_FLAGS', useValue: FeatureFlags },
    {
      provide: APP_INITIALIZER,
      useFactory: (featureFlagsService: FeatureFlagsService) => () => featureFlagsService.loadFeatureFlags(),
      deps: [ FeatureFlagsService ],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: () => new RetryPolicyInterceptor(envRetryConfig), // NOSONAR
      multi: true,
    },
    { provide: Window, useValue: window },
  ],
    
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
