import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tour',
  templateUrl: 'tour.component.html',
  styleUrls: ['tour.component.scss'],
})

export class TourMockupComponent {

  @Input() isTouring: boolean;

}
