import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SessionManager } from 'experian-authentication-frontend-components/dist'; // NOSONAR
import { JwtHelperService } from '@auth0/angular-jwt';
import { GuidedTourService } from 'ngx-guided-tour';
import { interval, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { guidedTour } from '../../../../src/app/services/steps';
import { LinksConfig } from '../../links.config';
import { Labels } from '../../shared/labels';
import { DecodedToken } from '../../shared/models/decoded-token';
import { TourEnum } from '../tour/tour.enum';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {

  public accessToken: string;

  public ApplicationLinks;
  public linksConfig = new LinksConfig();

  public myCards: any;
  public currentYear = new Date().getFullYear();

  public dashboardLabels = new Labels().dashboardLabels;
  public modalErrorTitle = this.dashboardLabels.modalErrorTitle;
  public modalErrorDesc = this.dashboardLabels.modalErrorDesc;

  public isTouring: boolean;
  public guidedTour = guidedTour;
  public userId: string;
  public tourStatePrefix = 'virtual-tour-';
  public openModalError = false;
  public isTourVirtualModalOpened = false;
  
  private _subs: Array<Subscription> = [];
  private _jwtHelper = new JwtHelperService()
  private _timer: ReturnType<typeof setTimeout>;
  private _tourVirtualModalTimer: ReturnType<typeof setTimeout>;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly sesisonManager: SessionManager,
    public tourService: GuidedTourService
  ) { }

  public ngOnInit() {
    try {
      this.accessToken = this.sesisonManager.accessToken;
      const tokenExpired = this._jwtHelper.isTokenExpired(this.accessToken);
      const decodedToken = this._decodeToken(this.accessToken);
      this.userId = decodedToken ? decodedToken.user_id : null;

      if (this.userId && !tokenExpired) {
        this._verifyTourStatus();
      }
    } catch($e) { }
    
    this._subs.push(
      interval(100)
        .pipe(first())
        .subscribe(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        })
    );

    this._subs.push(
      this.router.events.subscribe((e: any) => {
        if (e instanceof NavigationEnd) {
          const queryParams = this.activatedRoute.queryParams;
          const startTourCmd = queryParams['value']['starttour']; // eslint-disable-line
          if (startTourCmd === 'force') {
            setTimeout(() => {
              this.startTour(true);
            }, 500);
          } else if (startTourCmd !== undefined) {
            setTimeout(() => {
              this.startTour();
            }, 500);
          }
        }
      })
    );

    this._subs.push(this.activatedRoute.queryParams.subscribe(
      (params) => {
        if (params.starttour !== undefined) {
          setTimeout(() => {
            this.startTour(params['starttour'] === 'force'); // eslint-disable-line
          }, 500);
        }
      }
    ));

    this._subs.push(this.sesisonManager.logout$.subscribe(_ => {
      this.isTourVirtualModalOpened = false;
    }));

    this.guidedTour.skipCallback = () => {
      this.skippedTour();
    };

    this.guidedTour.completeCallback = () => {
      this.finishedTour();
    };

    this.ApplicationLinks = this.linksConfig;
  }

  public startTour(force = false): void {
    this.isTouring = true;
    // Force start even if the tour has already been finished
    const tourFinished = localStorage.getItem(
      this.tourStatePrefix + this.userId
    );
    if (force) {
      this.tourService.startTour(this.guidedTour);
    } else if (!tourFinished) {
      this.tourService.startTour(this.guidedTour);
    }
  }

  public clickModalInitTour() {
    this.isTourVirtualModalOpened = false;
    setTimeout(() => {
      this.startTour(true);
    }, 500);
  }

  public logout($event: any): void {
    if ($event && $event.success) {
      this.router.navigate(['/login']);
    } else {
      this._showModal();
    }
  }

  public response(response: any): void {
    if (response && response.success) {
      if (response.data.products) {
        this.myCards = response.data.products;
      } else {
        this.myCards = null;
      }
    } else {
      this.myCards = null;

      if (!response.myProducts) {
        this._showModal();
      }
    }
  }

  public _showModal(): Promise<any> {
    this.openModalError = true;
    return new Promise((resolve, reject) => {
      this._timer = setTimeout(() => {
        this.openModalError = false;
        resolve(this._timer);
      }, 5000);
    });
  }

  public renderResponse(response): void {
    if (!response.success) {
      this.myCards = null;
    } else {
      this.myCards = response && response.data && response.data.products;
    }
  }

  public skippedTour(): void {
    this.isTourVirtualModalOpened = false;
    this._closeTourModal(TourEnum.SKIPPED);
  }

  public finishedTour(): void {
    this.isTourVirtualModalOpened = false;
    this._closeTourModal(TourEnum.FINISHED);
  }

  public modalContractSuccessClosedEvent(isClosed: boolean): void {
    const tourStatus = localStorage.getItem(
      `${this.tourStatePrefix}${this.userId}`
    );

    if (!tourStatus) {
      this._openTourVirtualModal();
    }
  }

  private _closeTourModal(tourState: TourEnum): void {
    this.isTouring = false;
    localStorage.setItem(
      this.tourStatePrefix + this.userId,
      tourState
    );

    this.router.navigate(['./']);
    window.scrollTo({ top: 1 });
    window.scroll(0, 0);        
  }

  private _verifyTourStatus(): void {
    const tourStatus = localStorage.getItem(
      `${this.tourStatePrefix}${this.userId}`
    );

    const digitalContract = localStorage.getItem(`digitalContract`);

    if (!tourStatus && !digitalContract) {
      this._openTourVirtualModal();
    }
  }

  private _openTourVirtualModal() {
    clearTimeout(this._tourVirtualModalTimer);
    this._tourVirtualModalTimer = setTimeout(() => {
      this.isTourVirtualModalOpened = true;
    }, 2000);
  }

  private _decodeToken(token: string): DecodedToken {
    if (token) {
      return JSON.parse(atob(token.split('.')[1]));
    }
    return null;
  }

  ngOnDestroy() {
    clearTimeout(this._timer);
    this._subs.forEach(
      (sub: Subscription) => sub?.unsubscribe()
    );
  }

}
