import { TourStep, Orientation, GuidedTour } from 'ngx-guided-tour';
import { TourEnum } from '../components/tour/tour.enum';


export const tourSteps: Array<TourStep> = [
  {
    selector: '.profile',
    content: `
          <div class="tour-dlg">
            <div class="tour-dlg__icon-wrapper bg-french-blue">
                <span class="tour-dlg__icon-wrapper__username">SN</span>
            </div>
            <div class="tour-dlg__desc">
              <h3 class="tour-dlg__desc__title">MINHA CONTA</h3>
              <p class="tour-dlg__desc__text">
                Aqui você tem acesso às informações de sua conta. Em <strong>“Editar perfil”</strong> você consegue acessar mais informações da sua conta e as editar quando necessário. Em <strong>“Privacidade e segurança”</strong> e <strong>“Alterar senha”</strong>                                       você tem acesso a funcionalidades que ampliam a sua segurança em nosso sistema.
              </p>
            </div>
          <div>
          `,
    orientation: Orientation.BottomRight,
  },
  {
    selector: '#item-0', // item de 'Produtos'
    content: `
          <div class="tour-dlg">
            <div class="tour-dlg__icon-wrapper bg-french-blue">
              <span class="tour-dlg__icon-wrapper__icon material-icons">list_alt</span>
            </div>
            <div class="tour-dlg__desc">
              <h3 class="tour-dlg__desc__title">PRODUTOS</h3>
              <p class="tour-dlg__desc__text">
                Aqui você encontra um board com todos os seus produtos para que você possa acessá-los de forma rápida, fácil e simples.
              </p>
            </div>
          <div>
          `,
    orientation: Orientation.BottomLeft,
  },
  {
    selector: '#item-2', // item de 'Ferramentas'
    content: `
          <div class="tour-dlg">
            <div class="tour-dlg__icon-wrapper bg-french-blue">
              <span class="tour-dlg__icon-wrapper__icon material-icons">category</span>
            </div>
            <div class="tour-dlg__desc">
              <h3 class="tour-dlg__desc__title">FERRAMENTAS</h3>
              <p class="tour-dlg__desc__text">
                Aqui você tem acesso a ferramentas que auxiliam nas atividades diárias, tais como: catálogo e manuais dos produtos, geração de relatórios e portal de administração de acessos. 
              </p>
            </div>
          <div>
          `,
    orientation: Orientation.Bottom,
  },
  {
    selector: '#item-3', // item de 'Autoatendimento'
    content: `
          <div class="tour-dlg">
            <div class="tour-dlg__icon-wrapper bg-french-blue">
              <span class="tour-dlg__icon-wrapper__icon material-icons">touch_app_rounded</span>
            </div>
            <div class="tour-dlg__desc">
              <h3 class="tour-dlg__desc__title">AUTOATENDIMENTO</h3>
              <p class="tour-dlg__desc__text">
                Aqui você acessa todas informações do seus contratos, como <strong>Faturas, Histórico de faturamento</strong> e outros dados importantes.
              </p>
            </div>
          <div>
          `,
    orientation: Orientation.Bottom,
  },
  {
    selector: '#item-4', // item de 'Central de Ajuda'
    content: `
          <div class="tour-dlg">
            <div class="tour-dlg__icon-wrapper bg-french-blue">
              <span class="tour-dlg__icon-wrapper__icon material-icons">help</span>
            </div>
            <div class="tour-dlg__desc">
              <h3 class="tour-dlg__desc__title">CENTRAL DE AJUDA</h3>
              <p class="tour-dlg__desc__text">
                Precisa de ajuda? Disponibilizamos diversas formas para atender você e seu negócio.
              </p>
            </div>
          <div>
          `,
    orientation: Orientation.Bottom,
  },
  {
    selector: '.container-category-fiters', // Meus filtros (Empresas / Pessoas)
    content: `
          <div class="tour-dlg">
            <div class="tour-dlg__icon-wrapper bg-french-blue">
              <span class="tour-dlg__icon-wrapper__icon material-icons">search</span>
            </div>
            <div class="tour-dlg__desc">
              <h3 class="tour-dlg__desc__title">FILTRO DE PRODUTOS</h3>
              <p class="tour-dlg__desc__text">
                Nossos produtos são separados em “produtos para consultar empresas” e “produtos para consultar pessoas”. Utilize essa área para filtrá-los.
              </p>
            </div>
          <div>
          `,
    orientation: Orientation.BottomLeft,
  },
  {
    selector: '.search-box-wrap', // Barra de pesquisa
    content: `
          <div class="tour-dlg">
            <div class="tour-dlg__icon-wrapper bg-french-blue">
              <span class="tour-dlg__icon-wrapper__icon material-icons">search</span>
            </div>
            <div class="tour-dlg__desc">
              <h3 class="tour-dlg__desc__title">BUSCA POR PRODUTO</h3>
              <p class="tour-dlg__desc__text">
                Utilize o campo de busca para procurar por produtos que não esteja encontrando.
              </p>
            </div>
          <div>
          `,
    orientation: Orientation.Bottom,
    closeAction: () => {
      const filtersExpanded$ = document.getElementById('filters-expanded');
      if (filtersExpanded$) {
        filtersExpanded$.scrollIntoView();
      }
    }    
  },
  {
    selector: '.filters-expanded', // Pesquisa guiada expandida
    content: `
    <div class="tour-dlg">
      <div class="tour-dlg__icon-wrapper bg-french-blue">
        <span class="tour-dlg__icon-wrapper__icon material-icons">search</span>
      </div>
      <div class="tour-dlg__desc">
        <h3 class="tour-dlg__desc__title">BUSCA GUIADA</h3>
        <p class="tour-dlg__desc__text">
          Comece com a seleção de qual seria sua principal necessidade. De acordo com sua escolha, a segunda coluna irá se moldar e você poderá selecionar quantas opções achar preciso!
        </p>
      </div>
    <div>
    `,
    orientation: Orientation.Bottom,
    scrollAdjustment: 16,
  },
  {
    selector: '#results', // Resultados da pesquisa guiada expandida
    content: `
    <div class="tour-dlg">
      <div class="tour-dlg__icon-wrapper bg-french-blue">
        <span class="tour-dlg__icon-wrapper__icon material-icons">search</span>
      </div>
      <div class="tour-dlg__desc">
        <h3 class="tour-dlg__desc__title">BUSCA GUIADA</h3>
        <p class="tour-dlg__desc__text">
          Conforme você for selecionando os itens que lhe atendem, nosso portal realizará uma busca por todos os produtos que encaixam em sua necessidade! 
        </p>
      </div>
    <div>
    `,
    orientation: Orientation.Left,
    scrollAdjustment: 16,
  },
  {
    selector: '#prod-5ef1726cda18a7e9d4ee1b2b',
    content: `
    <div class="tour-dlg">
      <div class="tour-dlg__icon-wrapper bg-french-blue">
        <span class="tour-dlg__icon-wrapper__icon material-icons">search</span>
      </div>
      <div class="tour-dlg__desc">
        <h3 class="tour-dlg__desc__title">PRODUTO</h3>
        <p class="tour-dlg__desc__text">
          Cada caixa dessa é um dos seus produtos. Basta clicar no <strong>“Acessar”</strong> para ser encaminhado a página do produto desejado. Aproveite tambéms as funcionalidades abaixo:
        </p>
        <section class="tour-dlg__desc__product-features-container">
          <div class="tour-dlg__desc__product-features-container__item">
            <em class="material-icons tour-dlg__desc__product-features-container__item--icon"> stars </em>
            <p class="tour-dlg__desc__product-features-container__item--text">Favorite seus produtos</p>
          </div>
          <div class="tour-dlg__desc__product-features-container__item">
              <em class="material-icons tour-dlg__desc__product-features-container__item--icon"> help </em>
              <p class="tour-dlg__desc__product-features-container__item--text">Encontre maiores informações sobre o produto</p>
          </div>
        </section>
      </div>
    <div>
          `,
    orientation: Orientation.BottomLeft,
    action: () => {
      createRepeatButton();
      tourVirtualIsDone();
      setTimeout(() => {
        const product$: HTMLElement = document.getElementById(
          'prod-5ef1726cda18a7e9d4ee1b2b',
        );
        product$.scrollIntoView();
        const ngxEl$ = getNgxElement();
        ngxEl$[0].classList.add('is-last-step');
      }, 100);
    },
    closeAction: () => {
      setTimeout(() => {
        const ngxEl$ = getNgxElement();
        ngxEl$[0].classList.remove('is-last-step');
      }, 100);
    },
  },
];

const getNgxElement = (): NodeListOf<HTMLButtonElement> => {
  return document.querySelectorAll('ngx-guided-tour');
}

const tourVirtualIsDone = () => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const userId = decodeToken(accessToken)['user_id'];
    localStorage.setItem(`virtual-tour-${userId}`, TourEnum.FINISHED);
  } catch ($x) {}
};

const createRepeatButton = () => {
  let tourButtonsContainer = document.querySelector('.tour-buttons');
  const repeatTourButton$ = document.createElement('button');

  repeatTourButton$.classList.add('repeat-tour-button');
  repeatTourButton$.classList.add('back-button');
  repeatTourButton$.classList.add('link-button');

  repeatTourButton$.innerHTML = 'Repetir tour';
  repeatTourButton$.onclick = () => {
    window.open(window.location.href, '_self');
  };

  tourButtonsContainer.appendChild(repeatTourButton$);
}

const decodeToken = (token: string) => {
  if (token) {
    try {
      return  {
        ...JSON.parse(atob(token.split('.')[1])),
      };
    } catch (error) {
      return null;
    }
  } else {
    return null;
  }
}

export const guidedTour: GuidedTour = {
  /** Identifier for tour */
  tourId: 'tour01',
  /** Steps for the tour */
  steps: tourSteps,
  /** Minimum size of screen in pixels before the tour is run, if the tour is resized below this value the user will be told to resize */
  // minimumScreenSize: 860,
  preventBackdropFromAdvancing: true,
};
