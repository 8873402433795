import { environment } from '../environments/environment';

export class LinksConfig {

  environment = environment;

  public listLinks = [
    {
      title: 'Administração de acessos',
      url: 'AutoAtendimento/Default/menuAcessos.aspx',
      auth: true,
      param: true,
      testClass: 'authParam',
      icon: 'manage_accounts'
    }, {
      title: 'Manuais e treinamentos de produtos',
      url: 'https://www.serasaexperian.com.br/manuais-produtos/',
      noDomain: true,
      auth: false,
      param: false,
      testClass: 'param',
      icon: 'description',
      openNewTab: true,
    }, {
      title: 'Remessas de controladoras',
      url: 'experian-controle-remessa-web/index.vm',
      isPortal: true,
      param: true,
      testClass: 'onlyParam',
      icon: 'settings'
    }, {
      title: 'Remessas de dados consumidores',
      url: 'extratodados/PFExtratoDadosPrincipal',
      param: true,
      isPortal: true,
      testClass: 'onlyParam',
      icon: 'group'
    }, {
      title: 'Remessas de dados credit rating',
      url: '',
      noDomain: false,
      auth: false,
      param: false,
      testClass: 'authParam',
      icon: 'sort'
    }, {
      title: 'Remessas de dados empresas',
      url: 'AcompanhamentoRemessa/Default.aspx',
      param: true,
      testClass: 'onlyParam',
      icon: 'apartment'
    }
  ];
}
