import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';

import { default as pack } from '../../package.json';
import { environment } from '../environments/environment';

declare var dT_; // NOSONAR

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  public packageVersion = pack.version;
  public isTest = false;

  constructor(public http: HttpClient) {}

  ngOnInit() {
    if (typeof dT_ !== 'undefined' && dT_.initAngularNg) {
      dT_.initAngularNg(this.http, HttpHeaders);
    }

    if (environment.env === 'stg') {
      this._initDataDogForDevEnv();
    }
  }

  private _initDataDogForDevEnv(): void {
    if (!this.isTest) {
      console.log('>>> iniciando DataDog');

      datadogRum.init({
        applicationId: 'e4156ac7-8631-421e-acf0-7fcb8b87b96d',
        clientToken: 'pub04c7a623b209fcdae869d58e1e983461',
        site: 'datadoghq.com',
        service: 'menu-large',
        env: 'dev',
        version: this.packageVersion,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      });
    }
  }

}
