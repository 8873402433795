<section class="modal-first-access" id="modal-first-access">

  <div class="container-icon">
    <span class="material-icons icone-modal">visibility</span>
  </div>
  <span class="titulo-modal">Serasa Alerta</span>
  <span class="descricao-modal">
    Este é um serviço que monitora todos os CPFs e CNPJs consultados pela sua empresa de forma pró ativa e sem custo adicional.
  </span>
  <span class="descricao-modal">
    Aqui você visualiza alertas sobre qualquer alteração e acessa a lista com todos os documentos monitorados.
  </span>
    <button eds-button motif="primary" class="botao-fechar"
      appAnalyticsButton launchSection="MenuDeProdutos:SerasaAlertaNotificacao" 
      mat-button [mat-dialog-close]="true" cdkFocusInitial
      >Ok, entendi!</button>
</section>