import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { SessionManager } from '@experian/authentication'; // NOSONAR

@Injectable({
  providedIn: 'root'
})

export class PMEMonitoringService {

  eventIsValidAccessToMonitoringCenter = new EventEmitter<any>();

  constructor(
    readonly http: HttpClient,
    readonly authenticationService$: AuthService,
    private readonly sesisonManager: SessionManager
  ) {}

  public getDocument() {
    const uri = `${environment.urlPMEMonitoring}/companies/me?companyChannel=large`;
    return this.http.get<any>(uri, { headers: this.generateHttpHeader() });
  }

  public getAlerts(document: string) {
      const uri = `${environment.urlPMEMonitoring}/companies/alerts`;
      return this.http.get<any>(uri, { headers: this.generateHttpHeaderWithCompanyBase(document) });
  }

  public generateHttpHeader(): HttpHeaders {
    const accessToken = this.sesisonManager.accessToken;

    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      "If-None-Match": ""
    })
  }

  generateHttpHeaderWithCompanyBase(document: string): HttpHeaders {
    let header: HttpHeaders = this.generateHttpHeader();
    header = header.append('X-CompanyBase-Id', document)
    return header;
  }

  setMonitoringCenter(valid: boolean) {
    this.eventIsValidAccessToMonitoringCenter.emit(!!valid);
  }
}
