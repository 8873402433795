export const environment = {
  production: false,
  appName: 'MenuDeProdutos',
  env: 'sandbox',
  clientId: '60467d48dc81cc3acaf74560',
  showMobileModal: true,
  digitalCertificationLoginOption: 'mainframe',
  cacheExpiration: 900000, // You can set an expiration time for cache
  usePlatformRedirection: false,
  startTourAction: {
    title: 'Tour virtual',
    url: '/',
    queryParams: { starttour: 'force' },
  },
  showNotificationsOnStart: true,
  activateForgotPassword: true,
  siteNetProducts: false,
  useCategoryCookie: true,
  retryPolicyConfig: {
    maxRetryAttempts: 3,
    scalingDuration: 1000,
    urls: [
      {
        url: 'iam/v1',
        methods: ['POST'], // string[]
        statusCodes: [500, 501, 502, 503], // number[]
      },
      {
        url: 'add-event',
        methods: ['POST'], // string[]
        statusCodes: [500, 501, 502, 503], // number[]
      },
      {
        url: 'digital/product-access/v1/my-products',
        methods: ['GET'],
        statusCodes: [500, 501, 502, 503], // number[]
      },
    ],
  },
  activateShowWeakPassword: true,
  iconsSubMenu: {
    products: true,
    loginPortal: true,
    selfService: true,
    invoices: true,
    helpCenter: true
  },
  itemSubMenu: {
    products: true,
    loginPortal: true,
    selfService: true,
    invoices: true,
    helpCenter: true
  },
  urlMonitoring: 'https://serasa-alerta-uat.serasaexperian.com.br',
  PMEProductMenuUrl: '	https://empresas-uat.serasaexperian.com.br/meus-produtos/',
  urlPMEMonitoring: 'https://uat-api.serasaexperian.com.br/credit-services/pme-monitoring/v1',
  serasaAlertaClientId: '61fa8a1971797b45f19a4fa3'
};
