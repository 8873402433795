import { Component, Input, OnInit } from '@angular/core';
import { SessionManager } from '@experian/authentication'; // NOSONAR
import { take } from 'rxjs/operators';
import { PMEMonitoringService } from '../../services/pme-monitoring.service';

import { environment } from '../../../environments/environment';
import { ChangeApp } from 'experian-change-app-components';
import { Notifications } from '../../models/notifications.model';
import {MatDialog} from '@angular/material/dialog';
import { ModalFirstAccessSerasaAlertaComponent } from '../modal-first-access-serasa-alerta/modal-first-access-serasa-alerta.component';

@Component({
  selector: 'app-button-serasa-alerta',
  templateUrl: './button-serasa-alerta.component.html',
  styleUrls: ['./button-serasa-alerta.component.scss']
})
export class ButtonSerasaAlertaComponent implements OnInit {

  public isValidSerasaAlerta = false;
  public isValidSerasaAlertaTransaction = false;
  public alertCountCentralMonitoring: number;
  public decodedToken: any;
  public company: any;
  private readonly serasaAlertaProductInitials = "SA";

  @Input() myProduct: any;

  constructor(
    public dialog: MatDialog,
    public readonly sessionManager: SessionManager,
    public readonly pmeMonitoringService: PMEMonitoringService,
  ) { }

  ngOnInit() {
      this.decodedToken = this.decodeToken(this.sessionManager.accessToken);
      this.checkSerasaAlertaCard(this.myProduct);
  }

  public checkSerasaAlertaCard(myProduct: any){

    if(myProduct && myProduct.length > 0){
      this.isValidSerasaAlertaTransaction = myProduct.some(item => item.initials === this.serasaAlertaProductInitials);

      if(this.isValidSerasaAlertaTransaction){
        this.searchCnpjUserLogged();
      }
    }
  }

  public searchCnpjUserLogged() {
    this.pmeMonitoringService.getDocument().pipe(take(1)).subscribe(
      (response) => {
        this.isValidSerasaAlerta = response.isValidAccessToMonitoringCenter;
        this.pmeMonitoringService.setMonitoringCenter(this.isValidSerasaAlerta);
        if(this.isValidSerasaAlerta) {
          this.company = response.company;
          this.searchNotifications(response.company.companyDocument);
        }
      }
    );
  }

  public searchNotifications(document: string): void {
    this.pmeMonitoringService.getAlerts(document).pipe(take(1)).subscribe(
      (response: Notifications) => {
        this.alertCountCentralMonitoring = response.summary.count;
        this.analyticsAccessToMonitoringCenter();
      }
    );
  }

  public analyticsAccessToMonitoringCenter(): void {
    if (this.isValidSerasaAlerta) {

      document.DataLayer.custom = {
        events: ['CustomImpression']
      };

      if (this.alertCountCentralMonitoring) {
        document.DataLayer.custom.itemImpression = 'IMP:MENULARGE:SerasaAlerta-notification';
      } else {
        document.DataLayer.custom.itemImpression = 'IMP:MENULARGE:SerasaAlerta-no-notification';
      }

      document.DataLayer.rule = 'customLink';
      this.dispatchEvent('CustomImpression');

    }
  }

  public redirectToSerasaAlerta(): void {
    if(localStorage.viewButtonCentralMonitoramento > 0) {
      ChangeApp.init(this.generateChangeApp()).then(response => {
        if (response && response.success) {
          const minitoken = response.data.minitoken;
          const linkSerasaAlerta = `${environment.urlMonitoring}/alerta/login?minitoken=${minitoken}&param=${this.decodedToken.param}`;
          window.location.href = linkSerasaAlerta;
        } else {
          alert('Erro no sistema. Por favor tente novamente mais tarde');
        }
      });
    }else{
      this.openModalFirstAccess();
    }

  }

  public generateChangeApp(): any {
    return {
      accessToken: this.sessionManager.accessToken,
      env: environment.env,
      targetClientId: environment.serasaAlertaClientId,
      originClientId: environment.clientId,
      deviceId: this.decodedToken?.device_id
    };
  }

  public decodeToken(token: string) {
    if (token) {
      return JSON.parse(atob(token.split('.')[1]));
    }
    return null;
  }

  public dispatchEvent(eventName: string) {
    const satellite = window['_satellite'];

    if (satellite) {
      document.dispatchEvent(new CustomEvent(eventName, { detail: document.DataLayer }));
    } else {
      setTimeout(() => this.dispatchEvent(eventName), 500);
    }
  }

  openModalFirstAccess(): void {
    const dialogRef = this.dialog.open(ModalFirstAccessSerasaAlertaComponent, {
      width: '444px',
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if(confirm) {
        localStorage.setItem('viewButtonCentralMonitoramento', '1');
        this.redirectToSerasaAlerta();
      }
    });
  }

}
