<div class="col col-max">
  <button id="button-central-monitoramento"
          eds-button motif="primary"
          class="btn-primary-card"
          *ngIf="isValidSerasaAlerta && isValidSerasaAlertaTransaction" 
          appAnalyticsButton launchSection="MenuDeProdutos:SerasaAlerta"
          [launchLabel]="alertCountCentralMonitoring ? 'Serasa Alerta-notification' : 'Serasa Alerta-no-notification'"
          (click)="redirectToSerasaAlerta()">

    <span class="material-icons s18 icon-space">visibility</span>
    Serasa Alerta
    <span class="badge" *ngIf="alertCountCentralMonitoring">+</span>
  </button>
</div>
